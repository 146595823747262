import { JSX } from 'solid-js'
import { FontData } from '~/local-fonts'

const weights = [
  { name: 'Thin', value: 100 },
  { name: 'Hairline', value: 100 },
  { name: 'ExtraLight', value: 200 },
  { name: 'UltraLight', value: 200 },
  { name: 'Light', value: 300 },
  { name: 'SemiLight', value: 350 },
  { name: 'Normal', value: 400 },
  { name: 'Regular', value: 400 },
  { name: 'Medium', value: 500 },
  { name: 'SemiBold', value: 600 },
  { name: 'DemiBold', value: 600 },
  { name: 'Bold', value: 700 },
  { name: 'ExtraBold', value: 800 },
  { name: 'UltraBold', value: 800 },
  { name: 'Black', value: 900 },
  { name: 'Heavy', value: 900 },
  { name: 'ExtraBlack', value: 950 },
  { name: 'UltraBlack', value: 950 }
]

const styles = [
  { name: 'Italic', value: 'italic' }
]

const getFontCSS = (fontData: FontData) => {
  const fontCSS: JSX.CSSProperties = {}
  fontCSS['font-family'] = fontData.family
  fontCSS['font-weight'] = 'normal'
  fontCSS['font-style'] = 'normal'

  const fontProps = fontData.style.split(' ')
  fontProps.forEach(prop => {
    const weight = weights.find(weight => weight.name.toLowerCase() === prop.toLowerCase())
    if(weight){
      fontCSS['font-weight'] = weight.value
    }
    const style = styles.find(style => style.name.toLowerCase() === prop.toLowerCase())
    if(style){
      fontCSS['font-style'] = style.value
    }
    const uniqueVariant = !(weight || style)
    if (uniqueVariant){
      fontCSS['font-family'] = `${fontCSS['font-family']} ${prop}`
    }
  })
    
  return fontCSS
}

const getDefaultFontCSS = (fontData: FontData) => {
  const fontCSS: JSX.CSSProperties = {}
  fontCSS['font-family'] = fontData.family
    
  return fontCSS
}

const getWeightName = (fontData: FontData) => {
  let weightName = 'regular'
  
  const fontProps = fontData.style.split(' ')
  fontProps.forEach(prop => {
    const weight = weights.find(weight => weight.name.toLowerCase() === prop.toLowerCase())
    if(weight){
      weightName = prop
    }
  })
    
  return weightName.toLowerCase()
}

export {
  getFontCSS,
  getDefaultFontCSS,
  getWeightName
}